import {useEffect, useState} from "react"
import DatenOfPerson from "./components/DatenOfPerson";
import db from "./database/Config";
import { collection,arrayUnion ,onSnapshot,addDoc,doc,updateDoc} from "firebase/firestore";

function Home (){

    const[birthMonths,setBirthMounts]=useState("");
    const[ferien,setFerien]= useState(false);
    let[personDaten,setPersonDate]=useState({
        kurs:"",
        Birthday:"",
        dateKurs:"",
        time:"",
        ProbeMal:"",
        ENachname:"",
        EVorname:"",
        Vorname:"",
        Nachname:"",
        Phone:"",
        Email:"",
        Road:"",
        City:"",
        FrageText:"",
        confirmdata:false
    })
    let[kursList,setKursList]=useState([]);
    let[days,setDays]=useState([])
    let[nextWindow,setNextWindow]=useState(true)
    let[nextWindow2,setNextWindow2]=useState(false);
    let[nextWindow3,setNextWindow3]=useState(true);
    let[ProbeList,setProbeList]=useState([]);
    let[falseText,setFalseText]=useState("");
    let[groupText,setGroupText]=useState({text:"Für die angegebene Altersklasse bieten wir folgende Kurse an:",color:"black"})

    useEffect(() => {
       
        const gruppenCollectionRef1 = collection(db, 'Probe');
        const unsubscribe2 = onSnapshot(gruppenCollectionRef1, (snapshot) => {
            try {
                const data = snapshot.docs.map(doc => doc.data());
                let newList=[...data[0].Kurse]
                setProbeList(newList)
            } catch (error) {
                console.error('Fehler beim Verarbeiten des Snapshot:', error);
            }
        });
        
        // Returne eine Funktion, um die Listener zu deaktivieren
        return () => {
           
            unsubscribe2();
        };
    }, []);


    function calculateAgeInMonths(birthdate) {
        const birthDateObj = new Date(birthdate);
        const currentDate = new Date();
        // Berechnung des Alters in Monaten
        const yearsDiff = currentDate.getFullYear() - birthDateObj.getFullYear();
        const monthsDiff = currentDate.getMonth() - birthDateObj.getMonth();
        const ageInMonths = yearsDiff * 12 + monthsDiff;
        return ageInMonths;
    }

    let formatDate=(inputDate)=>{
        const today = new Date(inputDate);
        const day = today.getDate();
        const month = today.getMonth() + 1; // JavaScript Monate starten bei 0
        const year = today.getFullYear();
        return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
    }

    let KursList =()=>{
        let newList=[]
        for(let i=0; i<ProbeList.length; i++){
            if(birthMonths >= ProbeList[i].grenze1 && birthMonths <= ProbeList[i].grenze2){
                newList.push(ProbeList[i])
            }
        }
        setKursList(newList)
        setNextWindow(false)
        setNextWindow2(true)
    }

    let functionGroup=(kidx,aktiv)=>{
        if(!aktiv){
            setGroupText({text:"Leider gibt es in diesem Kurs keine freien Plätze.",color:"red"});
            setDays([]);
        }
        else{
            getNextDayOfWeek(kursList[kidx].day)
            setGroupText({text:kursList[kidx].group,color:"green"})
            setPersonDate({
                ...personDaten,
                kurs:kursList[kidx].group,
                time:kursList[kidx].time
            })
            
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    function getNextDayOfWeek(day) {
        const weekdays = ['Sonntag','Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
        const today = new Date();
        const todayIndex = today.getDay(); // Index des heutigen Wochentags
        const targetDayIndex = weekdays.indexOf(day); // Index des Ziel-Wochentags 
     
        let tage = todayIndex - targetDayIndex;
        let IndexDays = 0;
        if(tage < 0){
            IndexDays = -tage 
        }
        else if(tage > 0){
            IndexDays = 7 - tage
        }
        let uberNext = IndexDays + 7;
    
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + IndexDays);
        const uberNextDay = new Date(today);
        uberNextDay.setDate(today.getDate() + uberNext);
        let newList = [];
        newList.push(formatDate(nextDay));
        newList.push(formatDate(uberNextDay));
        setDays(newList)
    }

    let sendenFunction = async ()=>{
        let contract = birthMonths >= 216 ? false : true;
        let save = true
        if(personDaten.ProbeMal === ""){
            setFalseText("Bitte Probestunde aussuchen")
            save=false
        }
        else if(personDaten.Vorname === "" || personDaten.Nachname === ""){
            setFalseText("Bitte Teilnehmer Daten eingeben")
            save=false
        }
        else if(personDaten.ProbeMal === "1"){
            if(contract && (personDaten.ENachname ==="" || personDaten.EVorname === "")){
                setFalseText("Bitte Daten von Erziehungsberechtigten eingeben.")
                save=false
            }
            else if(personDaten.Phone === ""){
                setFalseText("Bitte Mobilnummer eingeben.")
                console.log(personDaten)
                save=false
            }
            else if(personDaten.Email === ""){
                setFalseText("Bitte E-Mail eingeben.")
                save=false
            }
            else if(personDaten.Road === ""){
                setFalseText("Bitte Straße & Hs-Nr. eingeben.")
                save=false
            }
            else if(personDaten.City === ""){
                setFalseText("Bitte PLZ & Ort eingeben.")
                save=false
            }
            else if(!personDaten.confirmdata){
                setFalseText("Bitte akzeptieren Sie die Datenschutzbedingungen.")
                save=false
            }
        }
        if(save){
            let NameList = "Users"
            const collectionRef = doc(db,"Probe","ProbeUser");

            updateDoc(collectionRef, {
                [NameList]: arrayUnion(personDaten)
            })
            .then(() => {
                console.log('JSON-Objekt erfolgreich zur Liste hinzugefügt!');
            })
            .catch((error) => {
                console.error('Fehler beim Hinzufügen des JSON-Objekts zur Liste:');
            });
            try{
                const collectionRef = collection(db,"mail");
                const RefDocument =await addDoc(collectionRef, { 
                    to: ["tanzatelierdance2impress@gmail.com"],
                    message:{
                        subject:"Probe",
                        text:"Vorname: "+personDaten.Vorname +" , "+"Nachname: "+personDaten.Nachname,
                        html: `
                                <ul>
                                    <li>${personDaten.Vorname}</li>
                                    <li>${personDaten.Nachname}</li>
                                    <li>${personDaten.Birthday}</li>
                                    <li>${personDaten.ProbeMal}</li>
                                    <li>${personDaten.kurs}</li>
                                    <li>${personDaten.dateKurs}</li>
                                    <li>${personDaten.time}</li>
                                    <li>${personDaten.FrageText}</li>
                                    <li>${personDaten.Email}</li>
                                    <li>${personDaten.Phone}</li>
                                    <li>${personDaten.Road}</li>
                                    <li>${personDaten.City}</li>
                                </ul>
                            `
                    }
                });
            }
            catch(error){
                    console.log(error)
            }
            setNextWindow3(false)
        }
        
    }
    let seiteReload = ()=>{
        window.location.reload()
    }

   

    return(
        <div>
            {ferien && <div>
                <header>
                    <div className="header-logo">
                        <img onClick={seiteReload} src="../img/logoklein.png" alt="logo"></img>
                    </div>
                </header>
                <div className="ferienText">
                    <p>In den Sommerferien (08.07.2024 – 20.08.2024) ist die Buchung einer Probestunde leider nicht möglich.</p>

                       <p> Ab dem 26.08.2024 könnt Ihr euch gerne wieder für eine Probestunde anmelden.</p>

                        <p>Wir wünschen euch schöne Sommerferien.</p>

                        <p>Euer Tanzatelier Dance 2 Impress</p>
                    
                </div>
                

            </div>}

            {!ferien && <div>
                <header>
                    <div className="header-logo">
                        <img onClick={seiteReload} src="../img/logoklein.png" alt="logo"></img>
                    </div>
                
                </header>
                <main>
                    <div  className="central-container">
                        {nextWindow3 && <div>
                            {nextWindow && <div>
                                <div className="header-text">
                                    <p>Bitte geben Sie das Geburtsdatum des Teilnehmers ein, damit Ihnen die geeigneten Kurse angezeigt werden.</p>
                                </div>
                                <div className="birthday-input">
                                    <input type="date" onChange={(e)=>{
                                                                    setBirthMounts(calculateAgeInMonths(e.target.value))
                                                                    setPersonDate({...personDaten, Birthday:formatDate(e.target.value)})
                                                                }}
                                                                onKeyDown={(event)=>{
                                                                    event.key === "Enter" && KursList()
                                                                }}
                                                                />
                                </div>
                                <div className="header-button">
                                    <button onClick={KursList}>Kurse anzeigen</button>
                                </div>
                            </div>}

                            {nextWindow2 && <div>
                                {kursList.length ===0 ? <div className="header-text"><p>Leider bieten wir momentan für diese Altersklasse keine Kurse an. Bitte setzen Sie sich telefonisch mit uns in Verbindung. </p></div> :
                                    <div className="kurse">
                                        <p style={{color:groupText.color}}>{groupText.text}</p>
                                        <div className="kurse-verfügbar">
                                            <div className="box-verfügbar"></div>
                                            <div className="text1-verfügbar" style={{marginRight:"10px"}}>Plätze verfügbar</div>
                                            <div className="box-verfügbar"></div>
                                            <div className="text2-verfügbar"  style={{marginRight:"10px"}}>Wenige Plätze verfügbar</div>
                                            <div className="box-verfügbar"></div>
                                            <div className="text3-verfügbar">Keine Plätze verfügbar</div>
                                        </div>
                                        {days.length !== 0 &&<div>
                                            <p className="datums-text">Wählen Sie ein Datum aus:</p>
                                            <div className="datums-kurs">
                                        
                                                {days.map((day,didx)=>{
                                                return(
                                                    <label htmlFor={day}  key={didx}>{day}
                                                    <input onChange={(e)=>{setPersonDate({...personDaten,dateKurs:e.target.value});setNextWindow2(false)}} id={day} value={day}  name="days" type="radio"/>
                                                    </label>
                                                )
                                                })}
                                            </div>
                                        </div>}
                                        <div className="kurse-table">
                                            {kursList.map((kurs,kid)=>{
                                                let color ="green"
                                                let aktiv = true;
                                                if(!kurs.valuable){
                                                    color="red";
                                                    aktiv=false;
                                                }
                                                if(kurs.devaluable) color="orange";
                                                return(
                                                    <div 
                                                        onClick={()=>{functionGroup(kid,aktiv)}} 
                                                        style={{backgroundColor:color}} 
                                                        className="kurs-karte" 
                                                        key={kid}
                                                    >
                                                        <p>{kurs.day}</p>
                                                        <p>{kurs.group}</p>
                                                        <p>{kurs.time}</p>
                                                        <p>{kurs.komentar1}</p>
                                                        <p>{kurs.komentar2}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>}
                            
                            </div>}
                            <div>
                                {personDaten.dateKurs!== "" && <div>
                                    
                                    <div className="end-text">
                                        <p><span>{personDaten.kurs}</span> am <span>{personDaten.dateKurs}</span> um <span>{personDaten.time}</span></p>
                                    </div>
                                    <div className="end-text-frage">
                                        <div>
                                            <label htmlFor="1">1. Probestunde</label>
                                            <input id="1" type="radio" name="ProbeMal"
                                            onChange={()=>{setPersonDate({...personDaten, ProbeMal:"1"})}}/>
                                        </div>
                                        <div>
                                            <label htmlFor="2">2. Probestunde</label>
                                            <input id="2" type="radio" name="ProbeMal"
                                            onChange={()=>{setPersonDate({...personDaten, ProbeMal:"2"})}}/>
                                        </div>
                                        <div>
                                            <label htmlFor="3">Vertragsabschluss</label>
                                            <input id="3" type="radio" name="ProbeMal"
                                            onChange={()=>{setPersonDate({...personDaten, ProbeMal:"Vertrag"})}}/>
                                        </div>
                                    </div>
                                    <div className="end-res-text">
                                        {personDaten.ProbeMal === "2" ? <p style={{color:"red"}}>Die Teilnahme an einer weiteren Probestunde kostet 5,00 Euro</p> : <p>Geben Sie bitte Ihre Daten ein</p>}
                                    </div>
                                
                                    <DatenOfPerson
                                        setPersonDate={setPersonDate}
                                        personDaten={personDaten}
                                        birthMonths={birthMonths}
                                    />
                                    <div className="false-text">
                                        <p>{falseText}</p>
                                    </div>
                                    <div className="header-button">
                                        <button onClick={sendenFunction}>Abschicken</button>
                                    </div>
                                </div>}
                            </div>
                        
                        </div>}
                        {!nextWindow3 &&<div className="end-Commentar">
                            <p>Vielen Dank für Ihre Anfrage. Sie erhalten umgehend eine E-Mail von uns. Bitte zeigen Sie diese in der Tanzschule vor.
                            </p>
                            <p className="end-Commentar-p2">Bitte warten Sie auf die E-Mail Bestätigung bevor Sie zur Probestunde erscheinen.</p>
                        </div>}
                    </div>
                </main>
            </div>}
        </div>
    )
}
export default Home;