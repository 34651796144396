import {getFirestore} from "firebase/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBA3RTSz7kyxApmegxpByFT-BqP4eRvWQc",
  authDomain: "presence-e8118.firebaseapp.com",
  projectId: "presence-e8118",
  storageBucket: "presence-e8118.appspot.com",
  messagingSenderId: "1015907633972",
  appId: "1:1015907633972:web:4149f678339fa351da3361"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db ;