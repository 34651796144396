import { useEffect, useState } from "react";

function DatenOfPerson(props){

    const birthMonths = props.birthMonths;
    let setPersonDate = props.setPersonDate;
    let personDaten = props.personDaten
    let contract = birthMonths >= 216 ? false : true;
    let[probeMal,setProbeMal]=useState(true)

    /*function normalizeName(name) {
        let removeSpace = name.replace(" ","")
        let newString = removeSpace.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
        console.log(newString)
        return newString
    }*/

    function normalizeName(name) {
        // Entfernen von Leerzeichen
        let trimmedName = name.trim();
        // Normalisieren von Vor- und Nachnamen
        let newString = trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1).toLowerCase()
        return newString;
    }
    
    useEffect(()=>{
        if(personDaten.ProbeMal === "2" || personDaten.ProbeMal === "Vertrag"){
            setProbeMal(false);
        }       
        else setProbeMal(true)
        
    },[personDaten.ProbeMal])

  
    return(
        <div>
           
            <form>
                <div className="form-first">
                    <div>
                        <input type="text" placeholder="Vorname des Teilnehmers*" 
                        onChange={(e)=>{setPersonDate({...personDaten,Vorname:normalizeName(e.target.value)})}}/>
                    </div>
                    <div>
                        <input type="text" placeholder="Nachname des Teilnehmers*"
                        onChange={(e)=>{setPersonDate({...personDaten,Nachname:normalizeName(e.target.value)})}}/>
                    </div>
                </div>
                <div className="end-birthday">
                    <p> Geburtsdatum : {personDaten.Birthday}</p>
                </div>
                {probeMal && <div>
                    {contract && <div>
                        <div className="end-birthday">
                            <p>Erziehungsberechtigte/-r bei Minderjährigen</p>
                        </div>
                        <div className="form-first">
                            <div>
                                <input type="text" placeholder="Vorname*"
                                onChange={(e)=>{setPersonDate({...personDaten,EVorname:e.target.value})}}/>
                            </div>
                            <div>
                                <input type="text" placeholder="Nachname*"
                                onChange={(e)=>{setPersonDate({...personDaten,ENachname:e.target.value})}}/>
                            </div>
                        </div>
                    </div>}
                    <div className="form-first">
                        <div>
                            <input type="tel" placeholder="Mobilnummer*"
                            onChange={(e)=>{setPersonDate({...personDaten,Phone:e.target.value})}}/>
                        </div>
                        <div>
                            <input type="email" placeholder="E-Mail*"
                            onChange={(e)=>{setPersonDate({...personDaten,Email:e.target.value})}}/>
                        </div>
                        <div>
                            <input type="text" placeholder="Straße Hs-Nr.*"
                            onChange={(e)=>{setPersonDate({...personDaten,Road:e.target.value})}}/>
                        </div>
                        <div>
                            <input type="text" placeholder="PLZ Ort*"
                            onChange={(e)=>{setPersonDate({...personDaten,City:e.target.value})}}/>
                        </div>
                    </div>
                    <div className="Datenschutz-con">
                        <div className="Datenschutz-daten">
                            <div className="confirm-par">
                                <input type="checkbox" value={personDaten.confirmdata}
                                onChange={(e)=>{setPersonDate({...personDaten,confirmdata:e.target.value})}}/>
                                <p>Ich erkläre mich mit der Verarbeitung der eingegebenen Daten, der <a href="https://dance2impress.de/KONTAKT/ImpressumAGB-Datenschutz/" target="_blank">Datenschutzerklärung</a> sowie den <a href="https://dance2impress.de/KONTAKT/ImpressumAGB-Datenschutz/" target="_blank">allgemeinen Geschäftsbedingungen</a> einverstanden.*</p>
                            </div>
                            <p>* Pflichtfelder</p>
                        </div>
                    </div>
                </div>}
                <div className="end-birthday">
                    <p>Möchten Sie uns noch etwas mitteilen?</p>
                </div>
                <div className="end-frage">
                    <textarea
                    onChange={(e)=>{setPersonDate({...personDaten,FrageText:e.target.value})}}
                    placeholder="Text"
                    />
                </div>
            </form>
        </div>
    )
}
export default DatenOfPerson;